import Profile from "./Profile/Profile";
import Footer from "./Footer/Footer";
import "./Home.css";

import React from "react";
import Header from "./Header/Header";

export default function Home() {
  return (
    <div className="home-container" id={"Home"}>
      <Header />
      <Profile />
      <Footer />
    </div>
  );
}
