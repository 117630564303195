import React, { useState, useEffect } from "react";
import "./Resume.css";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";

export default function Resume(props) {
  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
  const [carouselOffsetStyle, setCarouselOffsetStyle] = useState({});

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const ResumeHeading = (props) => {
    return (
      <div className="resume-heading">
        <div className="resume-main-heading">
          <div className="heading-bullet"></div>
          <span>{props.heading ? props.heading : ""}</span>
          {props.fromDate && props.toDate ? (
            <div className="heading-date">
              {props.fromDate + "-" + props.toDate}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="resume-sub-heading">
          <span>{props.subHeading ? props.subHeading : ""}</span>
        </div>
        <div className="resume-heading-description">
          <span>{props.description ? props.description : ""}</span>
        </div>
      </div>
    );
  };

  const resumeBullets = [
    { label: "Education", logoSrc: "education.svg" },
    { label: "Work History", logoSrc: "work-history.svg" },
    { label: "Programming Skills", logoSrc: "programming-skills.svg" },
    { label: "Projects", logoSrc: "projects.svg" },
    { label: "Interests", logoSrc: "interests.svg" },
  ];

  const programmingSkillsDetails = [
    { skill: "JavaScript", ratingPercentage: 75 },
    { skill: "React JS", ratingPercentage: 70 },
    { skill: "Node JS", ratingPercentage: 65 },
    { skill: "Express JS", ratingPercentage: 65 },
    { skill: "TypeScript", ratingPercentage: 65 },
    { skill: "Python", ratingPercentage: 70 },
    { skill: "Flask", ratingPercentage: 60 },
    { skill: "Django", ratingPercentage: 30 },
    { skill: "SQL", ratingPercentage: 65 },
    { skill: "CSS", ratingPercentage: 80 },
    { skill: "HTML", ratingPercentage: 80 },
  ];

  const projectDetails = [
    {
      title: "Personal Portfolio Website",
      duration: { fromDate: "2022", toDate: "2022" },
      description:
        "A personal portfolio website that displays my details and projects. You're looking at it!",
      subHeading: "Technologies Used: React JS, Bootstrap",
    },
    {
      title: "Shockleyfinancial.com",
      duration: { fromDate: "2021", toDate: "2022" },
      description: "A website for a finance company",
      subHeading: "Technologies Used: Grav CMS",
    },
    {
      title: "Boonelist",
      duration: { fromDate: "2021", toDate: "2021" },
      description:
        "A REST web app allowing users to create a profile and make posts of items for sale or services needed",
      subHeading:
        "Technologies Used: React JS, Node JS, Express JS, PostgreSQL, Bootstrap",
    },
    {
      title: "Job Locker",
      duration: { fromDate: "2021", toDate: "2021" },
      description:
        "A REST web app allowing users to create a profile and search/save job postings from the remotive.io API",
      subHeading: "Technologies Used: Flask, Jinja, PostgreSQL",
    },
  ];

  const resumeDetails = [
    <div className="resume-screen-container" key="education">
      <ResumeHeading
        heading={"Springboard.com"}
        subHeading={"Software Engineering Career Track"}
        fromDate={"2020"}
        toDate={"2021"}
      />
      <ResumeHeading
        heading={"Appalachian State University"}
        subHeading={"B.S. Anthropology with a Concentration in Archaeology"}
        fromDate={"2007"}
        toDate={"2013"}
      />
      <ResumeHeading
        heading={"High School"}
        subHeading={"Watauga High School"}
        fromDate={"2003"}
        toDate={"2007"}
      />
    </div>,

    <div className="resume-screen-container" key="work-experience">
      <div className="experience-container">
        <ResumeHeading
          heading={"Cheney Brothers, Inc."}
          subHeading={"District Sales Representative"}
          fromDate={"2018"}
          toDate={"2020"}
        />
        <div className="experience-description">
          <span className="resume-description-text">
            Managed territory accounts for wholesale food distribution and
            restored growth in a previously declining territory.
          </span>
        </div>
        {/* <div className="experience-description">
          <span className="resume-description-text">
            - Increased the territory corporate accounts by 70% in two years
            including opening the Appalachian State University and Linville
            Ridge Country Club accounts.
          </span>
          <br />
          <span className="resume-description-text">
            - Improved territory sales revenue by 30% during tenure. Territory
            sales and active accounts were previously in decline.
          </span>
          <br />
          <span className="resume-description-text">
            - Restored relationships with inactive or dying accounts and boosted
            item case count per delivery. Managed relationships and maximized
            revenues of over 25 existing wholesale accounts.
          </span>
          <br />
        </div> */}

        <ResumeHeading
          heading={"Mr. Tire"}
          subHeading={"Assistant Manager"}
          fromDate={"2016"}
          toDate={"2018"}
        />
        <div className="experience-description">
          <span className="resume-description-text">
            Managed a team of 12 employees and assisted in shop duties including
            service writing, automobile service, and inventory management.
          </span>
        </div>
        {/* <div className="experience-description">
        <span className="resume-description-text">
          - Increased the territory corporate accounts by 70% in two years
          including opening the Appalachian State University and Linville Ridge
          Country Club accounts.
        </span>
        <br />
        <span className="resume-description-text">
          - Improved territory sales revenue by 30% during tenure. Territory
          sales and active accounts were previously in decline.
        </span>
        <br />
        <span className="resume-description-text">
          - Restored relationships with inactive or dying accounts and boosted
          item case count per delivery. Managed relationships and maximized
          revenues of over 25 existing wholesale accounts.
        </span>
        <br />
      </div> */}
        <ResumeHeading
          heading={"The Restaurant at Gideon Ridge"}
          subHeading={"Front of House Manager"}
          fromDate={"2014"}
          toDate={"2018"}
        />
        <div className="experience-description">
          <span className="resume-description-text">
            Managed 7 front of house employees and ensured a five-star service
            protocol.
          </span>
        </div>
        {/* <div className="experience-description">
        <span className="resume-description-text">
          - Increased the territory corporate accounts by 70% in two years
          including opening the Appalachian State University and Linville Ridge
          Country Club accounts.
        </span>
        <br />
        <span className="resume-description-text">
          - Improved territory sales revenue by 30% during tenure. Territory
          sales and active accounts were previously in decline.
        </span>
        <br />
        <span className="resume-description-text">
          - Restored relationships with inactive or dying accounts and boosted
          item case count per delivery. Managed relationships and maximized
          revenues of over 25 existing wholesale accounts.
        </span>
        <br />
      </div> */}
      </div>
    </div>,
    <div
      className="resume-screen-container programming-skills-container"
      key="programming-skills"
    >
      {programmingSkillsDetails.map((skill, index) => (
        <div className="skill-parent" key={index}>
          <div className="heading-bullet"></div>
          <span>{skill.skill}</span>
          <div className="skill-percentage">
            <div
              style={{ width: skill.ratingPercentage + "%" }}
              className="active-percentage-bar"
            ></div>
          </div>
        </div>
      ))}
    </div>,
    <div className="resume-screen-container" key="projects">
      {projectDetails.map((projectDetails, index) => (
        <ResumeHeading
          key={index}
          heading={projectDetails.title}
          subHeading={projectDetails.subHeading}
          description={projectDetails.description}
          fromDate={projectDetails.duration.fromDate}
          toDate={projectDetails.duration.toDate}
        />
      ))}
    </div>,
    <div className="resume-screen-container interests" key="interests">
      <ResumeHeading
        heading="Family"
        description="Being a husband and father is the ultimate reward. I love spending time with my family and experiencing the joys of life with my kiddos."
      />

      <ResumeHeading
        heading="Running"
        description="When I'm not writing code, I'm usually running on one of the trails in my beautiful mountain town."
      />

      <ResumeHeading
        heading="Cooking"
        description="The ultimate fusion of art and science! My favorite meal to cook is my homemade 'hillbilly' ramen."
      />

      <ResumeHeading
        heading="Games"
        description="Though my time to play video games continues to diminish as time goes on, it's one of the best ways to stay in touch with old friends. I also enjoy board games and hide-and-seek, my son's favorite game."
      />
    </div>,
  ];

  const handleCarousel = (index) => {
    let offsetHeight = 360;

    let newCarouselOffset = {
      style: { transform: "translateY(" + index * offsetHeight * -1 + "px)" },
    };

    setCarouselOffsetStyle(newCarouselOffset);
    setSelectedBulletIndex(index);
  };

  const getBullets = () => {
    return resumeBullets.map((bullet, index) => (
      <div
        onClick={() => handleCarousel(index)}
        className={
          index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"
        }
        key={index}
      >
        <img
          className="bullet-logo"
          src={require(`../../assets/Resume/${bullet.logoSrc}`)}
          alt="B"
        />
        <span className="bullet-label">{bullet.label}</span>
      </div>
    ));
  };

  const getResumeScreen = () => {
    return (
      <div
        style={carouselOffsetStyle.style}
        className="resume-details-carousel"
      >
        {resumeDetails.map((ResumeDetail) => ResumeDetail)}
      </div>
    );
  };

  useEffect(() => {
    return () => {
      /* UNSUBSCRIBE THE SUBSCRIPTIONS */
      fadeInSubscription.unsubscribe();
    };
  }, [fadeInSubscription]);

  return (
    <div
      className="resume-container screen-container fade-in"
      id={props.id || ""}
    >
      <div className="resume-content">
        <ScreenHeading title={"Resume"} subHeading={"My Formal Bio Details"} />
        <div className="resume-card">
          <div className="resume-bullets">
            <div className="bullet-container">
              <div className="bullet-icons"></div>
              <div className="bullets">{getBullets()}</div>
            </div>
          </div>

          <div className="resume-bullet-details">{getResumeScreen()}</div>
        </div>
      </div>
    </div>
  );
}
