import Typical from "react-typical";

import imgBack from "../../../src/images/mailz.jpeg";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import Footer from "../../PortfolioContainer/Footer/Footer";
import "./ContactInfo.css";

export default function ContactMe(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  return (
    <div className="main-container fade-in" id={props.id || ""}>
      <ScreenHeading subHeading={"Keep in Touch"} title={"Contact Me"} />
      <div className="central-form">
        <div className="col"></div>
        <div className="back-form">
          <div className="contact-info email">
            <h2>Email</h2>
            <h3>
              <a href="mailto:garnerdavidf@gmail.com">garnerdavidf@gmail.com</a>
            </h3>
          </div>

          <div className="contact-info phone">
            <h2>Phone</h2>
            <h3>(828) 773-6865</h3>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
